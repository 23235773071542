import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import '../styles/chat.css';
//import '../styles/buttons.css';

const socket = io.connect('https://danezah.com'); // Use your production server URL

const Chat = () => {
    const { username } = useParams(); // Chat partner's username from the URL
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [user, setUser] = useState(null); // Logged-in user's details
    const chatBoxRef = useRef(null); // Reference for the chat box
    const textareaRef = useRef(null); // Reference for the textarea

    // Request browser notification permission on mount
    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission !== 'granted') {
                        console.warn('Notification permission denied');
                    }
                })
                .catch((error) => {
                    console.error('Error requesting notification permission:', error);
                });
        }
    }, []);

    // Fetch logged-in user details on component mount
    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setUser(data.user); // Save logged-in user's details
                    socket.emit('register', data.user.username); // Register user with the WebSocket server
                } else {
                    console.error('User not authenticated');
                }
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
            });
    }, []);

    // Fetch chat history and listen for incoming messages
    useEffect(() => {
        if (user) {
            fetch(`/messages/${username}`, { credentials: 'include' })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setMessages(data.messages); // Load chat history
                    } else {
                        console.error('Failed to load messages');
                    }
                })
                .catch((error) => console.error('Error fetching messages:', error));

            socket.on('receive_message', (message) => {
                setMessages((prev) => [...prev, message]); // Add new incoming messages

                // Show browser notification
                if (
                    message.sender !== user.username &&
                    'Notification' in window &&
                    Notification.permission === 'granted'
                ) {
                    new Notification(`New message from ${message.sender}`, {
                        body: message.message,
                        icon: '/path-to-icon.png', // Optional: Path to a notification icon
                    });
                }
            });

            return () => socket.off('receive_message');
        }
    }, [username, user]);

    // Automatically scroll to the latest message
    useEffect(() => {
        if (chatBoxRef.current) {
            // Use smooth scrolling for better UX
            chatBoxRef.current.scrollTo({
                top: chatBoxRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messages]); // Trigger scrolling when messages change

    // Send a new message
    const sendMessage = () => {
        if (newMessage.trim() && user) {
            const message = {
                sender: user.username, // Logged-in user's username
                receiver: username, // Chat partner's username
                message: newMessage,
            };

            // Emit the message via WebSocket
            socket.emit('send_message', message);

            // Clear input field
            setNewMessage('');
            adjustTextareaHeight(); // Reset textarea height after sending
        }
    };

    // Adjust textarea height dynamically
    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Adjust height dynamically
        }
    };

    // Handle key presses in the textarea
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const handleKeyDown = (e) => {
        if (isMobile) {
            // On mobile, pressing Enter should add a newline
            if (e.key === 'Enter') {
                adjustTextareaHeight(); // Adjust height for newlines
            }
        } else {
            // Desktop behavior
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevent default newline
                sendMessage(); // Send message
            } else if (e.key === 'Enter' && e.shiftKey) {
                adjustTextareaHeight(); // Adjust height dynamically
            }
        }
    };

    return (
        <div className="chat-container">
            <h1>Chat with {username}</h1>
            <div className="chat-box" ref={chatBoxRef}>
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`message ${msg.sender === user?.username ? 'sent' : 'received'}`}
                        data-timestamp={new Date(msg.timestamp).toLocaleTimeString()} // Add timestamp as a data attribute
                    >
                        <p>{msg.message}</p>
                    </div>
                ))}
            </div>

            <div className="input-container">
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);
                        adjustTextareaHeight(); // Dynamically adjust height
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Type a message..."
                    rows="1" // Initial rows
                    style={{
                        resize: 'none', // Disable manual resizing
                        overflow: 'hidden', // Prevent scrollbars
                        padding: '8px 10px', // Comfortable padding
                        fontSize: '14px', // Adjust font size
                        height: 'auto', // Ensure height grows dynamically
                        lineHeight: '1.5', // Maintain consistent spacing
                    }}
                ></textarea>

                <button className="send-button" onClick={sendMessage}>
                    ✈️
                </button>
            </div>
        </div>
    );
};

export default Chat;
