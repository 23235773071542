import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/userlist.css';
//import '../styles/buttons.css';

const Volunteer = () => {
    const [helpseekers, setHelpseekers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Verify the user's role
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    if (data.user.role === 'volunteer') {
                        setIsAuthorized(true);
                    } else if (data.user.role === 'helpseeker') {
                        navigate('/helpseeker'); // Redirect to Helpseeker page if they are a helpseeker
                    } else {
                        navigate('/unauthorized'); // Redirect if the role is invalid
                    }
                } else {
                    navigate('/signin'); // Redirect if not authenticated
                }
            })
            .catch((error) => {
                console.error('Error checking authorization:', error);
                navigate('/unauthorized');
            });
    }, [navigate]);

    useEffect(() => {
        if (isAuthorized) {
            const fetchChats = () => {
                fetch('/helpseeker/chats', { credentials: 'include' })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setHelpseekers(data.chats);
                        } else {
                            console.error('Error fetching chats');
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching chats:', error);
                        setLoading(false);
                    });
            };

            fetchChats();

            // Poll for new messages every 5 seconds
            const interval = setInterval(fetchChats, 5000);
            return () => clearInterval(interval); // Cleanup interval on component unmount
        }
    }, [isAuthorized]);

    const handleLogout = () => {
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        window.location.href = '/auth/logout';
    };

    const startChat = (username) => {
        navigate(`/chat/${username}`);
    };

    if (!isAuthorized) {
        return <div>Checking authorization...</div>;
    }

    if (loading) {
        return <div>Loading chats...</div>;
    }

    return (
        <div className="user-list-container">
            <header className="header">
                <h1>Helpseekers in Need</h1>
                <button className="button" onClick={handleLogout}>
                    Logout
                </button>
            </header>
            <div className="user-list">
                {helpseekers.length > 0 ? (
                    helpseekers.map((helpseeker) => (
                        <div
                            className="user-item"
                            key={helpseeker.username}
                            onClick={() => startChat(helpseeker.username)}
                        >
                            <img src={helpseeker.avatar || '/default-avatar.png'} alt={`${helpseeker.username}'s Avatar`} />
                            <div className="user-details">
                                <h2>{helpseeker.username}</h2>
                                <p>{helpseeker.lastMessage || 'No message yet'}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="no-helpseekers">
                        If Helpseeker sees you in the panel, they will connect. :)
                    </div>
                )}
            </div>

        </div>
    );
};

export default Volunteer;
