import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/chatregister.css';
//import '../styles/buttons.css';

const ChatPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(data.user);

                    if (data.user.role && data.user.username) {
                        if (data.user.role === 'helpseeker') {
                            navigate('/helpseeker');
                        } else if (data.user.role === 'volunteer' && data.user.volunteerApproved) {
                            navigate('/volunteer');
                        }
                    } else {
                        setShowRoleSelection(true); // Show role selection
                    }
                } else {
                    setIsAuthenticated(false);
                }
            })
            .catch((err) => {
                console.error('Error checking authentication:', err);
                setErrorMessage('Failed to load user data.');
                setShowRoleSelection(true); // Fall back to showing role selection
            });
    }, [navigate]);


    const handleSignIn = () => {
        window.location.href = '/auth/google';
    };

    const handleLogout = () => {
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        window.location.href = '/auth/logout';
    };

    const handleRoleSelection = (role) => {
        setSelectedRole(role);
        setErrorMessage('');
    };

    const handleSubmit = () => {
        if (!username.trim() || (selectedRole === 'helpseeker' && !phone.trim())) {
            setErrorMessage('Please fill all required fields.');
            return;
        }

        const userDetails = {
            username,
            role: selectedRole,
            phone: selectedRole === 'volunteer' && !phone.trim() ? null : phone,
        };

        fetch('/auth/save-user-details', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userDetails),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    localStorage.setItem('role', selectedRole);
                    localStorage.setItem('username', username);
                    if (selectedRole === 'helpseeker') {
                        navigate('/helpseeker');
                    } else if (selectedRole === 'volunteer') {
                        setErrorMessage('Your application is under review.');
                    }
                } else {
                    setErrorMessage('Error saving user details.');
                }
            })
            .catch((err) => console.error('Error saving user details:', err));
    };

    return (
        <div className="chat-page-container">
            <header className="header">
                {isAuthenticated && user ? (
                    <div className="user-info">
                        <img src={user.image} alt="User Icon" className="user-icon" />
                        <span>{user.displayName}</span>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                ) : (
                    <div className="sign-in-container">
                        <p>You need to sign in to access the chat.</p>
                        <button className="button" onClick={handleSignIn}>
                            Sign in with Google
                        </button>
                    </div>
                )}
            </header>

            {isAuthenticated ? (
                showRoleSelection ? (
                    <div className="role-selection">
                        <div className="username-container">
                            <label>
                                Username:
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </label>
                        </div>

                        <div className="role-selection-container">
                            <h3>Select Your Role</h3>
                            <button
                                className="button"
                                onClick={() => handleRoleSelection('helpseeker')}
                            >
                                Helpseeker
                            </button>
                            <button
                                className="button"
                                onClick={() => handleRoleSelection('volunteer')}
                            >
                                Volunteer
                            </button>
                        </div>

                        {selectedRole === 'helpseeker' && (
                            <div className="phone-details">
                                <label>
                                    Phone Number (Required for Helpseekers):
                                    <input
                                        type="text"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </label>
                            </div>
                        )}

                        {selectedRole === 'volunteer' && (
                            <div className="phone-details">
                                <label>
                                    Phone Number (Optional for Volunteers):
                                    <input
                                        type="text"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </label>
                            </div>
                        )}

                        <button className="button" onClick={handleSubmit}>
                            Submit
                        </button>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                ) : (
                    <p>Loading role selection...</p>
                )
            ) : (
                <div className="sign-in-container"></div>
            )}
        </div>
    );
};

export default ChatPage;
