import React, { useEffect, useState } from 'react';

const TherapyAdmin = () => {
    const [pendingTherapists, setPendingTherapists] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state for fetch operations
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // Fetch pending therapists
        fetch('therapistadmin/pending')
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setPendingTherapists(data.pendingTherapists || []);
                } else {
                    setErrorMessage('Failed to load pending therapists.');
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching pending therapists:', error);
                setErrorMessage('An error occurred while loading pending therapists.');
                setLoading(false);
            });
    }, []);

    const approveTherapist = (id) => {
        if (!window.confirm('Are you sure you want to approve this therapist?')) {
            return; // Exit if user cancels confirmation
        }

        // Approve therapist
        fetch(`therapistadmin/approve/${id}`, {

            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setPendingTherapists((prev) =>
                        prev.filter((therapist) => therapist._id !== id)
                    );
                    setSuccessMessage('Therapist approved successfully.');
                } else {
                    setErrorMessage('Failed to approve therapist.');
                }
            })
            .catch((error) => {
                console.error('Error approving therapist:', error);
                setErrorMessage('An error occurred. Please try again later.');
            });
    };

    return (
        <div className="therapy-admin-panel">
            <h1>Therapy Admin Panel</h1>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <h2>Pending Therapists</h2>
            {loading ? (
                <p>Loading pending therapists...</p>
            ) : pendingTherapists.length > 0 ? (
                <ul>
                    {pendingTherapists.map((therapist) => (
                        <li key={therapist._id}>
                            <p>
                                <strong>Name:</strong> {therapist.displayName} <br />
                                <strong>Email:</strong> {therapist.email} <br />
                                <strong>Phone:</strong> {therapist.phone || 'N/A'}
                            </p>
                            <button
                                onClick={() => approveTherapist(therapist._id)}
                                style={{ marginRight: '10px' }}
                            >
                                Approve
                            </button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No pending therapists.</p>
            )}
        </div>
    );
};

export default TherapyAdmin;
