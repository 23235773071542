import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/bookingpage.css';

const BookingPage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [showRoleSelection, setShowRoleSelection] = useState(false);
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedTherapyRole, setSelectedTherapyRole] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/auth/check', { credentials: 'include' })
            .then((response) => response.json())
            .then((data) => {
                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(data.user);

                    if (data.user.therapyrole) {
                        if (data.user.therapyrole === 'wellnessseeker') {
                            navigate('/wellnessseeker-dashboard');
                        } else if (data.user.therapyrole === 'therapist' && data.user.therapistApproved) {
                            navigate('/therapist-dashboard');
                        }
                    } else {
                        setShowRoleSelection(true); // Ask for role selection
                    }
                } else {
                    setShowRoleSelection(true); // Show role selection for unauthenticated users
                }
            })
            .catch((err) => {
                console.error('Error checking authentication:', err);
                setErrorMessage('Failed to load user data.');
                setShowRoleSelection(true); // Fall back to showing role selection
            });
    }, [navigate]);


    const handleRoleSelection = (role) => {
        setSelectedTherapyRole(role);
        setErrorMessage('');
    };

    const handleSubmit = () => {
        if (!selectedTherapyRole) {
            setErrorMessage('Please select a therapy role.');
            return;
        }

        // Only require username if it�s not already set in user data
        if (!username.trim() && !user?.username) {
            setErrorMessage('Username is required.');
            return;
        }

        setLoading(true); // Start loading indicator

        const userDetails = {
            username: user?.username || username, // Use existing username or input value
            therapyrole: selectedTherapyRole,
            phone: selectedTherapyRole === 'therapist' ? phone.trim() || null : null,
        };

        fetch('/auth/save-user-details', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userDetails),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false); // Stop loading indicator
                if (data.success) {
                    // Redirect to appropriate dashboard
                    if (selectedTherapyRole === 'wellnessseeker') {
                        navigate('/wellnessseeker-dashboard');
                    } else if (selectedTherapyRole === 'therapist') {
                        setErrorMessage('Your application is under review.');
                    }
                } else {
                    setErrorMessage(data.message || 'Error saving user details.');
                }
            })
            .catch((err) => {
                setLoading(false); // Stop loading indicator
                console.error('Error saving user details:', err);
                setErrorMessage('An error occurred. Please try again.');
            });
    };

    return (
        <div className="booking-page-container">
            <header className="header">
                {isAuthenticated && user ? (
                    <div className="user-info">
                        <img src={user.image} alt="User Icon" className="user-icon" />
                        <span>{user.displayName}</span>
                        <button onClick={() => (window.location.href = '/auth/logout')}>
                            Logout
                        </button>
                    </div>
                ) : (
                    <div className="sign-in-container">
                        <p>You need to sign in to access this page.</p>
                        <button
                            className="button"
                            onClick={() => (window.location.href = '/auth/google')}
                        >
                            Sign in with Google
                        </button>
                    </div>
                )}
            </header>

            {isAuthenticated ? (
                showRoleSelection ? (
                    <div className="role-selection">
                        {!user?.username && (
                            <div className="username-container">
                                <label>
                                    Username:
                                    <input
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </label>
                            </div>
                        )}

                        <div className="role-selection-container">
                            <h3>Select Your Role</h3>
                            <button
                                className="button"
                                onClick={() => handleRoleSelection('wellnessseeker')}
                            >
                                WellnessSeeker
                            </button>
                            <button
                                className="button"
                                onClick={() => handleRoleSelection('therapist')}
                            >
                                Therapist
                            </button>
                        </div>

                        {selectedTherapyRole === 'therapist' && (
                            <div className="phone-details">
                                <label>
                                    Phone Number (Required for Therapists):
                                    <input
                                        type="text"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Enter phone number"
                                    />
                                </label>
                            </div>
                        )}

                        <button className="button" onClick={handleSubmit} disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                ) : (
                    <p>Loading role selection...</p>
                )
            ) : (
                <div className="sign-in-container"></div>
            )}
        </div>
    );
};

export default BookingPage;
