import React, { useState, useEffect } from 'react';
import axios from 'axios';

const WellnessSeekerDashboard = ({ wellnessSeekerId }) => {
    const [therapists, setTherapists] = useState([]);
    const [selectedTherapist, setSelectedTherapist] = useState(null);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [therapyType, setTherapyType] = useState('');
    const [price, setPrice] = useState(0);

    useEffect(() => {
        const fetchTherapists = async () => {
            try {
                const response = await axios.get('/api/therapists');
                setTherapists(response.data);
            } catch (error) {
                console.error('Error fetching therapists:', error);
            }
        };
        fetchTherapists();
    }, []);

    const handleTherapistSelect = (therapist) => {
        setSelectedTherapist(therapist);
        setPrice(0); // Reset price when therapist changes
    };

    const handleBooking = async () => {
        if (!selectedTherapist || !date || !time || !therapyType) {
            alert('Please fill all fields');
            return;
        }

        try {
            const { _id: therapistId } = selectedTherapist;
            const response = await axios.post('/api/bookings/create', {
                therapist: therapistId,
                wellnessSeeker: wellnessSeekerId,
                date,
                time,
                therapyType,
                price,
            });
            alert('Booking created successfully!');
        } catch (error) {
            console.error('Error creating booking:', error);
        }
    };

    return (
        <div>
            <h2>Wellness Seeker Dashboard</h2>
            <div>
                <h4>Available Therapists</h4>
                <ul>
                    {therapists.map((therapist) => (
                        <li key={therapist._id} onClick={() => handleTherapistSelect(therapist)}>
                            {therapist.name} - {therapist.specialties.join(', ')}
                        </li>
                    ))}
                </ul>
            </div>

            {selectedTherapist && (
                <div>
                    <h4>Book a Session</h4>
                    <label>Date:</label>
                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    <label>Time:</label>
                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />
                    <label>Therapy Type:</label>
                    <select
                        value={therapyType}
                        onChange={(e) => {
                            setTherapyType(e.target.value);
                            setPrice(selectedTherapist.pricing[e.target.value] || 0);
                        }}
                    >
                        <option value="">Select Therapy Type</option>
                        {selectedTherapist.therapyTypes.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                    <p>Price: {price}</p>
                    <button onClick={handleBooking}>Book Now</button>
                </div>
            )}
        </div>
    );
};

export default WellnessSeekerDashboard;
