import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css'
import '../styles/home.css';

const HomePage = () => {
    const navigate = useNavigate();
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const savedTheme = localStorage.getItem("darkMode");
        if (savedTheme === "enabled") {
            document.body.classList.add("dark-mode");
            setDarkMode(true);
        }
    }, []);

    const toggleTheme = () => {
        const isDark = !darkMode;
        setDarkMode(isDark);
        document.body.classList.toggle("dark-mode", isDark);
        localStorage.setItem("darkMode", isDark ? "enabled" : "disabled");
    };

    return (
        <div>
            <header className="navbar">
                <h1 className="kit-title">Wellness Haven 🌿</h1>
                <button onClick={toggleTheme} className="theme-toggle">
                    {darkMode ? "Light Mode 🌞" : "Dark Mode 🌙"}
                </button>
            </header>

            <section className="hero">
                <h1>Experience Wellness Like Never Before with Danezah</h1>
                <p>Your journey to better mental health starts here.</p>
                <button className="button"  onClick={() => navigate('/assessment')}>
                    Take Your First Step
                </button>
            </section>

            <section className="features">
                <div className="feature" onClick={() => navigate('/assessment')}>
                    <div className="feature-icon">📝</div>
                    <h2 className="feature-title">Take Assessment</h2>
                    <p className="feature-description">Discover insights into your mental health.</p>
                </div>
                <div className="feature" onClick={() => navigate('/chat')}>
                    <div className="feature-icon">💬</div>
                    <h2 className="feature-title">Live Chat</h2>
                    <p className="feature-description">Speak with professionals in real-time.</p>
                </div>
                <div className="feature" onClick={() => navigate('/journaling')}>
                    <div className="feature-icon">📖</div>
                    <h2 className="feature-title">Journaling</h2>
                    <p className="feature-description">Track your thoughts and moods daily.</p>
                </div>
                <div className="feature" onClick={() => navigate('/booking')}>
                    <div className="feature-icon">📅</div>
                    <h2 className="feature-title">Book a Therapist</h2>
                    <p className="feature-description">Find and schedule sessions with experts.</p>
                </div>
                <div className="feature" onClick={() => navigate('/response-help')}>
                    <div className="feature-icon">❓</div>
                    <h2 className="feature-title">How Shall I Respond?</h2>
                    <p className="feature-description">Learn effective coping mechanisms.</p>
                </div>
                <div className="feature" onClick={() => navigate('/resources')}>
                    <div className="feature-icon">📚</div>
                    <h2 className="feature-title">Resources</h2>
                    <p className="feature-description">Explore articles and tools for self-help.</p>
                </div>
            </section>

            <footer className="footer">
                <p>&copy; 2024 Danezah Inc. All rights reserved.</p>
                <p>Need help? <a href="/contact-us" className="footer-link">Contact us</a></p>
            </footer>
        </div>
    );
};

export default HomePage;
