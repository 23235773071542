import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TherapistDashboard = ({ therapistId }) => {
    const [availability, setAvailability] = useState([]);
    const [therapyTypes, setTherapyTypes] = useState([]);
    const [pricing, setPricing] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTherapistDetails = async () => {
            try {
                const response = await axios.get(`/api/therapists/${therapistId}`);
                const { availability, therapyTypes, pricing } = response.data;
                setAvailability(availability || []);
                setTherapyTypes(therapyTypes || []);
                setPricing(pricing || {});
            } catch (error) {
                console.error('Error fetching therapist details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTherapistDetails();
    }, [therapistId]);

    const handleAvailabilityChange = (index, field, value) => {
        const updatedAvailability = [...availability];
        updatedAvailability[index] = { ...updatedAvailability[index], [field]: value };
        setAvailability(updatedAvailability);
    };

    const handleAddAvailability = () => {
        setAvailability([...availability, { day: '', startTime: '', endTime: '' }]);
    };

    const handleTherapyTypeChange = (type) => {
        setTherapyTypes((prev) =>
            prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
        );
    };

    const handlePricingChange = (type, price) => {
        setPricing((prev) => ({ ...prev, [type]: price }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`/api/therapists/update-availability/${therapistId}`, {
                availability,
                therapyTypes,
                pricing,
            });
            alert('Details updated successfully!');
        } catch (error) {
            console.error('Error updating details:', error);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div>
            <h2>Therapist Dashboard</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <h4>Availability</h4>
                    {availability.map((slot, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                placeholder="Day"
                                value={slot.day}
                                onChange={(e) => handleAvailabilityChange(index, 'day', e.target.value)}
                            />
                            <input
                                type="time"
                                value={slot.startTime}
                                onChange={(e) =>
                                    handleAvailabilityChange(index, 'startTime', e.target.value)
                                }
                            />
                            <input
                                type="time"
                                value={slot.endTime}
                                onChange={(e) => handleAvailabilityChange(index, 'endTime', e.target.value)}
                            />
                        </div>
                    ))}
                    <button type="button" onClick={handleAddAvailability}>
                        Add Slot
                    </button>
                </div>

                <div>
                    <h4>Therapy Types and Pricing</h4>
                    {['Telephonic', 'Video Call', 'In-Person Therapy', 'Home Therapy'].map((type) => (
                        <div key={type}>
                            <label>
                                <input
                                    type="checkbox"
                                    value={type}
                                    checked={therapyTypes.includes(type)}
                                    onChange={() => handleTherapyTypeChange(type)}
                                />
                                {type}
                            </label>
                            <input
                                type="number"
                                placeholder="Price"
                                value={pricing[type] || ''}
                                onChange={(e) => handlePricingChange(type, e.target.value)}
                            />
                        </div>
                    ))}
                </div>

                <button type="submit">Save Details</button>
            </form>
        </div>
    );
};

export default TherapistDashboard;
