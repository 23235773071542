import React, { useState, useEffect } from 'react';
import axios from 'axios';
import  "../styles/journaling.css";
//import "../styles/buttons.css"


const JournalingPage = () => {
    const [entries, setEntries] = useState([]);
    const [currentEntry, setCurrentEntry] = useState('');
    const [mood, setMood] = useState('neutral');
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    // Check authentication on component load
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get('/auth/check', { withCredentials: true });
                if (response.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                    fetchEntries();
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);
    useEffect(() => {
        document.body.className = `mood-${mood}`;
    }, [mood]);
    useEffect(() => {
        const streak = localStorage.getItem('streak') || 0;
        // Increment streak if journaling today
    }, [entries]);

    const calculateWordCloud = () => {
        const wordCounts = {};
        entries.forEach(entry => {
            entry.content.split(' ').forEach(word => {
                wordCounts[word] = (wordCounts[word] || 0) + 1;
            });
        });
        return wordCounts;
    };

    const prompts = {
        neutral: "What are you thinking about today?",
        happy: "What made you smile today?",
        sad: "What's weighing on your mind?",
        stressed: "What's causing stress and how might you alleviate it?",
    };

    const getPrompt = () => prompts[mood] || "Start writing your thoughts...";

    const fetchEntries = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/journals', { withCredentials: true });
            setEntries(response.data); // Backend now only returns user-specific journals
            setLoading(false);
        } catch (error) {
            console.error('Error fetching entries:', error);
            setLoading(false);
        }
    };


    const handleSave = async () => {
        if (!currentEntry) return alert('Please write something before saving!');

        try {
            const response = await axios.post('/journals', { content: currentEntry, mood });
            setEntries([response.data, ...entries]);
            setCurrentEntry('');
            setMood('neutral');
        } catch (error) {
            console.error('Error saving entry:', error);
        }
    };

    const handleSignIn = () => {
        window.location.href = '/auth/google'; // Replace with your sign-in endpoint
    };

    const handleLogout = () => {
        window.location.href = '/auth/logout'; // Replace with your logout endpoint
    };

    return (
        <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
            <h1>Journaling</h1>
            {!isAuthenticated ? (
                <div>
                    <p>You need to sign in to access the journaling app.</p>
                    <button class="button" onClick={handleSignIn} style={{ padding: '10px 20px' }}>
                        Sign in with Google
                    </button>
                </div>
            ) : (
                <div>
                    <header style={{ marginBottom: '20px' }}>
                        <div>
                            <p>Welcome, {user?.displayName}!</p>
                                <button class="button"  onClick={handleLogout} style={{ padding: '10px 20px' }}>
                                Logout
                            </button>
                        </div>
                    </header>
                    <textarea
                        style={{ width: '100%', height: '150px', marginBottom: '10px', padding: '10px' }}
                        value={currentEntry}
                        onChange={(e) => setCurrentEntry(e.target.value)}
                        placeholder="Write your thoughts here..."
                    />
                    <select
                        style={{ marginBottom: '10px', padding: '5px' }}
                        value={mood}
                        onChange={(e) => setMood(e.target.value)}
                    >
                        <option value="neutral">Neutral</option>
                        <option value="happy">Happy</option>
                        <option value="sad">Sad</option>
                        <option value="stressed">Stressed</option>
                    </select>
                    <br />
                        <button class="button" onClick={handleSave} style={{ padding: '10px 20px', marginBottom: '20px' }}>
                        Save
                    </button>
                    {loading && <p>Loading...</p>}
                    <h2>Past Entries</h2>
                    <ul>
                        {entries.map((entry) => (
                            <li key={entry._id} style={{ marginBottom: '15px', border: '1px solid #ccc', padding: '10px' }}>
                                <p>{entry.content}</p>
                                <small>{new Date(entry.date).toLocaleString()} - Mood: {entry.mood}</small>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default JournalingPage;
