import React, { useState } from 'react';

const TelephonicTherapy = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [preferredTime, setPreferredTime] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Telephonic Therapy Scheduled:', { phoneNumber, preferredTime });
        alert('Your telephonic therapy has been scheduled!');
    };

    return (
        <div>
            <h1>Schedule a Telephonic Therapy</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter your phone number"
                        required
                    />
                </label>
                <br />
                <label>
                    Preferred Time:
                    <input
                        type="time"
                        value={preferredTime}
                        onChange={(e) => setPreferredTime(e.target.value)}
                        required
                    />
                </label>
                <br />
                <button type="submit">Schedule</button>
            </form>
        </div>
    );
};

export default TelephonicTherapy;
