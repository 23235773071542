import React, { useState } from 'react';

const InPersonTherapy = () => {
    const [location, setLocation] = useState('');
    const [appointmentDate, setAppointmentDate] = useState('');
    const [appointmentTime, setAppointmentTime] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('In-Person Therapy Scheduled:', { location, appointmentDate, appointmentTime });
        alert('Your in-person therapy has been scheduled!');
    };

    return (
        <div>
            <h1>Schedule an In-Person Therapy</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Location:
                    <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Enter location for therapy"
                        required
                    />
                </label>
                <br />
                <label>
                    Date:
                    <input
                        type="date"
                        value={appointmentDate}
                        onChange={(e) => setAppointmentDate(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Time:
                    <input
                        type="time"
                        value={appointmentTime}
                        onChange={(e) => setAppointmentTime(e.target.value)}
                        required
                    />
                </label>
                <br />
                <button type="submit">Schedule</button>
            </form>
        </div>
    );
};

export default InPersonTherapy;
