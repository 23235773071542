import React, { useEffect, useState } from 'react';

const AdminPanel = () => {
    const [pendingVolunteers, setPendingVolunteers] = useState([]);
    const [pendingTherapists, setPendingTherapists] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Fetch pending volunteers
        fetch('/admin/pending-volunteers')
            .then((response) => response.json())
            .then((data) => setPendingVolunteers(data))
            .catch((error) => {
                console.error('Error fetching pending volunteers:', error);
                setErrorMessage('Failed to load pending volunteers.');
            });

        // Fetch pending therapists
        fetch('/admin/pending-therapists') // Corrected URL
            .then((response) => response.json())
            .then((data) => setPendingTherapists(data)) // Process the array directly
            .catch((error) => {
                console.error('Error fetching pending therapists:', error);
                setErrorMessage('Failed to load pending therapists.');
            });
    }, []);


    const approveEntity = (id, type) => {
        const endpoint =
            type === 'therapist'
                ? `/admin/approve-therapist/${id}`
                : `/admin/approve-volunteer/${id}`;

        fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    if (type === 'therapist') {
                        setPendingTherapists((prev) => prev.filter((t) => t._id !== id));
                    } else {
                        setPendingVolunteers((prev) => prev.filter((v) => v._id !== id));
                    }
                } else {
                    setErrorMessage(`Failed to approve ${type}.`);
                }
            })
            .catch((error) => {
                console.error(`Error approving ${type}:`, error);
                setErrorMessage('An error occurred. Please try again later.');
            });
    };

    const disapproveEntity = (id, type) => {
        const endpoint =
            type === 'therapist'
                ? `/admin/disapprove-therapist/${id}`
                : `/admin/disapprove-volunteer/${id}`;

        fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    if (type === 'therapist') {
                        setPendingTherapists((prev) => prev.filter((t) => t._id !== id));
                    } else {
                        setPendingVolunteers((prev) => prev.filter((v) => v._id !== id));
                    }
                } else {
                    setErrorMessage(`Failed to disapprove ${type}.`);
                }
            })
            .catch((error) => {
                console.error(`Error disapproving ${type}:`, error);
                setErrorMessage('An error occurred. Please try again later.');
            });
    };


    return (
        <div className="admin-panel">
            <h1>Admin Panel</h1>
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <div style={{ display: 'flex', gap: '40px' }}>
                {/* Pending Volunteers */}
                <div>
                    <h2>Pending Volunteers</h2>
                    {pendingVolunteers.length > 0 ? (
                        <ul>
                            {pendingVolunteers.map((volunteer) => (
                                <li key={volunteer._id}>
                                    <p>
                                        <strong>Name:</strong> {volunteer.displayName} <br />
                                        <strong>Email:</strong> {volunteer.email} <br />
                                        <strong>Phone:</strong> {volunteer.phone || 'N/A'}
                                    </p>
                                    <button
                                        onClick={() => approveEntity(volunteer._id, 'volunteer')}
                                        style={{ marginRight: '10px' }}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => disapproveEntity(volunteer._id, 'volunteer')}
                                        style={{ backgroundColor: '#dc3545', color: '#fff' }}
                                    >
                                        Disapprove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No pending volunteers.</p>
                    )}
                </div>

                {/* Pending Therapists */}
                <div>
                    <h2>Pending Therapists</h2>
                    {pendingTherapists.length > 0 ? (
                        <ul>
                            {pendingTherapists.map((therapist) => (
                                <li key={therapist._id}>
                                    <p>
                                        <strong>Name:</strong> {therapist.displayName} <br />
                                        <strong>Email:</strong> {therapist.email} <br />
                                        <strong>Phone:</strong> {therapist.phone || 'N/A'}
                                    </p>
                                    <button
                                        onClick={() => approveEntity(therapist._id, 'therapist')}
                                        style={{ marginRight: '10px' }}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={() => disapproveEntity(therapist._id, 'therapist')}
                                        style={{ backgroundColor: '#dc3545', color: '#fff' }}
                                    >
                                        Disapprove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No pending therapists.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;
